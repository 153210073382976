import React from 'react';

import Layout from '../components/Layout';
import { Heading2, Paragraph } from '../components/Heading/Text';
import WebsiteButton from '../components/WebsiteButton';
import Container from '../components/Container';
import FooterBanner from '../components/FooterBanner/FooterBanner';

const NotFoundPage = () => (
  <Layout>
    <Container css={{ flexDirection: 'column' }}>
      <div>
        <Heading2 primary light>
          Page Introuvable
        </Heading2>
        <Paragraph>La page que vous demandez est introuvable.</Paragraph>
        <WebsiteButton href="/">Retour</WebsiteButton>
      </div>
    </Container>
    <FooterBanner />
  </Layout>
);

export default NotFoundPage;
